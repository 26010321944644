import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
    width: 100%;
    margin-top: ${({ $marginTop }) =>
        $marginTop || "0"}; /* $marginTop으로 변경 */
`;

const Container = ({ children, marginTop }) => {
    return <StyledContainer $marginTop={marginTop}>{children}</StyledContainer>;
};

const AlignmentContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 32px;

    @media all and (max-width: 1024px) {
        padding: 0;
    }
`;

export { Container, AlignmentContainer };