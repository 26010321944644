import styled from "styled-components";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import useLanguageStore from "../store/useLanguageStore";

const details_1 = {

    

    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Data Transformation",
            mainText: "데이터가 곧 자산인 시대. 이를 위한 변화",
        },
    ],

    functions: [
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_1_1002.jpg",
            title: "Data Base Building",
            sub: "데이터베이스를 통해 기업의 정보 자산 활용 최적화",
            reason: "데이터기반의 의사결정이 필요하십니까? 정형화된 데이터베이스 구축은 데이터를 손쉽게 추출, 시각화하여 의사결정시 효율적으로 활용할수 있고,  추후 AI 모델 적용도 가능케 합니다. ",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_2.jpg",
            title: "Cloud Migration",
            sub: "로컬 데이터의 클라우드 이전을 통해 보안과 접근성을 확보하며, 비용과 운영 효율성 개선",
            reason: "서버 관리나 전담 보안 팀이 없는 회사에서도 클라우드 마이그레이션을 통해 글로벌 레벨의 향상된 보안과 높은 데이터 접근성을 확보할 수 있습니다. DataCore을 통해 보안 위험을 줄이고, 데이터 관리를 간소화하세요.",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_3_1002.jpg",
            title: "Live visualization",
            sub: "기업의 퍼포먼스를 내부 KPI에 맞춰 실시간 점검할수 있도록 시각화한 BI 솔루션 구축",
            reason: "단순 데이터 보유만으로 사업성과가 달라지지 않습니다. 기업활동의 현재시점 성과를 바로 확인하고 정확한 인사이트를 바탕으로 빠르게 의사결정할수 있는 실시간 시각화가 가능한 대시보드 구축이 필요합니다. ",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_4.jpg",
            title: "Data Pipelining / 업무 자동화",
            sub: "Datacore의 자동화 솔루션으로 업무 프로세스 최적화",
            reason: "자동화 솔루션으로 시간소모적인 반복적 업무에서 벗어나 직원들이 빠르게 업무 수행이 가능해짐에따라 업무 효율성 및 생산성이 대폭 향상될수 있습니다. ",
        },
    ],
};

const details_2 = {
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_1.jpg",
            title: "시장 데이터 분석",
            sub: "당사 SKU들의 등수, 경쟁 SKU들의 가격대, 경쟁사들의 사용중인 hashtag, 마케팅 메시지 확인",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_2.jpg",
            title: "광고",
            sub: "당사 광고의 키워드별, SKU별 퍼포먼스 확인 및 트랜드 분석",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_3.jpg",
            title: "리뷰",
            sub: "네이버, 쿠팡, 카페24, 오늘의 집 등의 채널에서 본사와 경쟁사의 리뷰 수집 및 비교",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_4.jpg",
            title: "인터넷 여론 분석",
            sub: "메타 / 유튜브 / 네이버 카페, 블로그에서 관심 키워드 별 연관되어 있는 자연어 분석",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_5.jpg",
            title: "연관성 분석",
            sub: "현재 당사의 매출 / 검색 수 등과 가장 연관성이 높은 마케팅 / 매출 요소 확인",
        },
        {
            funcImg: "https://images.unsplash.com/photo-1595675024853-0f3ec9098ac7?q=80&w=3456&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            title: "앱 크롤링",
            sub: "앱 Emulator를 사용한 당사 / 타사 앱에서의 데이터 수집 및 분석",
        },
    ],
};

const details_3 = {
    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Machine Learning model building ",
            mainText:
                "복잡한 머신러닝도 이제 간편하게. 내 회사에 최적화된 맞춤 솔루션 구축 가능",
        },
    ],
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_1.jpg",
            title: "마케팅 비용 최적화 모델",
            sub: "마케팅 예산의 효율성을 극대화하여 광고 투자의 효과를 최적화합니다. 이를 통해 귀사의 브랜드 가시성을 향상시켜 지속 가능한 성장을 지원합니다. 예) 우리제품에 적합한 네이버 광고비용은? 이 금액을 투자하면 매출은 어느정도 확대될까?",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_2.jpg",
            title: "Supply Chain을 위한 수요 예측 모델",
            sub: "다양한 시장 조건에 대응하여 MOQ를 최적화하고, 필요 재고 수준을 예측합니다. 공급망의 효율을 극대화하고 비용을 절감할 수 있는 전략적 모델을 제공합니다. 예) 다음달이 성수기인데 어느정도 재고가 필요할까? 새로 주문하는 MOQ가 최적수준인가?",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_3.jpg",
            title: "당사 비지니스에 특화된 강화 학습 모델",
            sub: "시장 변화보다 앞서가는 전략. 매출을 예측해 최적의 비즈니스 액션을 제안하는 AI 모델이 경쟁에서 차별화를 이끌어냅니다. Ex) 시장 상황과 우리 회사의 매출을 고려할때 다음달 사업활동을 어떻게 진행해야할지 알려주는 툴은 없을까?",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/chatbot_img.jpeg",
            title: "당사 데이터를 학습 시킨 RAG 베이스 소프트웨어",
            sub: "AI가 데이터를 검색하고, 그 위에 창의적인 콘텐츠를 생성합니다. Ex) 우리 제품에 대한 질문을 자동으로 답해주는 챗봇이 있으면 좋겠어",
        },
    ],
};



const details_en1 = {

    

    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Data Transformation",
            mainText:  "Please Write",
        },
    ],

    functions: [
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_1_1002.jpg",
            title: "Data Base Building",
            sub:  "Please Write",
            reason:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_2.jpg",
            title: "Cloud Migration",
            sub:  "Please Write",
            reason:  "Please Write",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/level1_3_1002.jpg",
            title: "Live visualization",
            sub:  "Please Write",
            reason:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level1_4.jpg",
            title:  "Please Write",
            sub:  "Please Write",
            reason:  "Please Write",
        },
    ],
};

const details_en2 = {
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_1.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_2.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_3.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_4.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level2_5.jpg",
            title:  "Please Write",
            sub: "Please Write",
        },
    ],
};

const details_en3 = {
    main: [
        {
            // mainImage: "https://heidimoon.cafe24.com/renwal/test2/detail_main.jpg",
            mainTitle: "Machine Learning model building ",
            mainText:
                 "Please Write",
        },
    ],
    functions: [
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_1.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_2.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg: "https://heidimoon.cafe24.com/renwal/test2/level3_3.jpg",
            title:  "Please Write",
            sub:  "Please Write",
        },
        {
            funcImg:
                "https://heidimoon.cafe24.com/renwal/test2/chatbot_img.jpeg",
            title:  "Please Write",
            sub:  "Please Write",
        },
    ],
};

const getDetailsById = (id) => {
    switch (id) {
        case "1":
            return details_1;
        case "2":
            return details_2;
        case "3":
            return details_3;
        default:
            return [];
    }
};

const EnGetDetailsById = (id) => {
    switch (id) {
        case "1":
            return details_en1;
        case "2":
            return details_en2;
        case "3":
            return details_en3;
        default:
            return [];
    }
};
const Detail = () => {
    const { id } = useParams(); // URL 파라미터에서 id를 가져옴
    const details = getDetailsById(id); // id에 따라 데이터를 선택
    const EnDetails = EnGetDetailsById(id);
    const language = useLanguageStore((state) => state.language);

    // id가 "2"인 경우에만 다른 UI를 렌더링
    if (id === "2") {
        return <DetailCase2 />;
    }

    // 그 외의 id는 기존 UI를 렌더링
    return (
        <>

                    {language === "ko" ? (
                    <DetailWrapper>
                                    <DetailMainContainer>
                                        <Wrapper>
                                            <MainWrapper>
                                                <DetailTitle>
                                                    {details.main[0].mainTitle}
                                                </DetailTitle>
                                                <MainImageWrapper>
                                                    <MainImage src={details.main[0]?.mainImage} />
                                                </MainImageWrapper>
                                                <DetailSub>{details.main[0]?.mainText}</DetailSub>
                                            </MainWrapper>
                                        </Wrapper>
                                    </DetailMainContainer>

                                    
                                    {details.functions.map((detail, index) => (
                                        <DetailContainer key={index}>
                                            <Wrapper reverse={index % 2 === 0}>
                                                <ImageWrapper reverse={index % 2 === 1}>
                                                    <DetailImg src={detail.funcImg} />
                                                </ImageWrapper>
                                                <TextWrapper>
                                                    <DetailText>
                                                        {index + 1}. {detail.title}
                                                    </DetailText>
                                                    <DetailSub>{detail.sub}</DetailSub>
                                                    {detail.reason && (
                                                        <>
                                                            <DetailText>Why?</DetailText>
                                                            <DetailSub>{detail.reason}</DetailSub>
                                                        </>
                                                    )}
                                                </TextWrapper>
                                            </Wrapper>
                                        </DetailContainer>
                                    ))}
                                </DetailWrapper>

                                ) : (
                    <DetailWrapper>
                                    <DetailMainContainer>
                                        <Wrapper>
                                            <MainWrapper>
                                                <DetailTitle>
                                                    {EnDetails.main[0].mainTitle}
                                                </DetailTitle>
                                                <MainImageWrapper>
                                                    <MainImage src={EnDetails.main[0]?.mainImage} />
                                                </MainImageWrapper>
                                                <DetailSub>{EnDetails.main[0]?.mainText}</DetailSub>
                                            </MainWrapper>
                                        </Wrapper>
                                    </DetailMainContainer>

                                    
                                    {EnDetails.functions.map((EnDetails, index) => (
                                        <DetailContainer key={index}>
                                            <Wrapper reverse={index % 2 === 0}>
                                                <ImageWrapper reverse={index % 2 === 1}>
                                                    <DetailImg src={EnDetails.funcImg} />
                                                </ImageWrapper>
                                                <TextWrapper>
                                                    <DetailText>
                                                        {index + 1}. {EnDetails.title}
                                                    </DetailText>
                                                    <DetailSub>{EnDetails.sub}</DetailSub>
                                                    {EnDetails.reason && (
                                                        <>
                                                            <DetailText>Why?</DetailText>
                                                            <DetailSub>{EnDetails.reason}</DetailSub>
                                                        </>
                                                    )}
                                                </TextWrapper>
                                            </Wrapper>
                                        </DetailContainer>
                                    ))}
                                </DetailWrapper>

                    )}
            
        </>
    );
};
export default Detail;

const DetailWrapper = styled.div`
    padding: 120px 0;
    background-color: #010633;
    height: auto;
    width: 100%;
`;

const DetailMainContainer = styled.div`
    background-color: #010633;
    height: auto;
    width: 100%;
    padding: 0 16px;
`;

const DetailContainer = styled.div`
    margin-top: 64px;
    background-color: #010633;
    height: auto;
    width: 100%;
    padding: 0 16px;
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column; /* 768px 이하에서는 이미지와 텍스트를 세로로 쌓기 */
        justify-content: center;
        align-items: center;
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%; /* 텍스트도 전체 너비 사용 */
    }
`;

const MainImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%; /* 작은 화면에서는 전체 너비를 사용 */
        justify-content: center; /* 중앙 정렬 */
    }
`;

const MainImage = styled.img`
    width: 100%;
    max-width: 400px;
    height: auto;
    box-sizing: border-box;
    object-fit: none;
    border-radius: 30px;
`;

const ImageWrapper = styled.div`
    width: 50%;
    display: flex;
    justify-content: ${({ reverse }) => (reverse ? "flex-start" : "flex-end")};
    align-items: center;

    @media (max-width: 768px) {
        width: 100%; /* 작은 화면에서는 전체 너비를 사용 */
        justify-content: center; /* 중앙 정렬 */
        max-width: 400px;
    }
`;

const DetailImg = styled.img`
    width: 100%;
    height: auto;
    box-sizing: border-box;
    object-fit: contain;
    border-radius: 30px;
`;

const TextWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        max-width: 400px;
    }
`;

const DetailTitle = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo};
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 1.3;
    color: white;

    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const DetailText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 1.3;
    color: white;

    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const DetailSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small} !important;
    color: white;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.fonts.regular} !important;
    margin-top: 1rem;
    margin-bottom: 64px;

    @media all and (min-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        padding: 0 32px;
    }
`;
const DetailCase2 = () => {
    const language = useLanguageStore((state) => state.language); 
    const [isMobile, setIsMobile] = useState(window.innerWidth < 465);

    // 윈도우 리사이즈 이벤트 처리
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 465);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const currentFunctions = language === "ko" ? details_2.functions : details_en2.functions;

    return (
        <SpecialWrapper>
            <SpecialText>Data Analytics</SpecialText>
            <SubText>
                {language === "ko"
                    ? "Datacore와 함께 트랜드 변화를 누구보다 빠르게 파악 하세요.  모든 질문의 답은 데이터 안에 있습니다."
                    : "Please Write"}
            </SubText>

            <SpecialContainer>
                <SpecialFunctions>
                    <SpecialTitle>
                        {language === "ko"
                            ? isMobile
                                ? ""
                                : "온라인에 존재하는 데이터를 원하는 scope에 따라 모아드립니다."
                            : isMobile
                            ? "Please Write"
                            : "Please Write"}
                    </SpecialTitle>
                    <SpecialSub>
                        {language === "ko"
                            ? "Datacore은 기업의 필요조건에 맞게 외부데이터를 대신 수집해드립니다. 경쟁사 및 본사의 현재 상황을 파악하는 데 필수적인 데이터를 제공합니다. "
                            : "Please Write"}
                    </SpecialSub>
                    <SpecialSub>
                        {language === "ko"
                            ? "* 자사의 데이터베이스가 필수로 제공되어야 합니다."
                            : "Please Write"}
                    </SpecialSub>
                </SpecialFunctions>
            </SpecialContainer>

            <SliderWrapper>
                <Slider {...settings}>
                    {currentFunctions.map((item, index) => (
                        <AnalysisWrapper key={index}>
                            <Analysis>
                                <AnalysisImage src={item.funcImg} alt={item.title} />
                                <AnalysisTitle>{item.title}</AnalysisTitle>
                                <AnalysisSub>{item.sub}</AnalysisSub>
                            </Analysis>
                        </AnalysisWrapper>
                    ))}
                </Slider>
            </SliderWrapper>

            <SpecialContainer>
                <SpecialFunctions>
                    <SpecialTitle>
                        {language === "ko"
                            ? "기업에 직면한 사업상 문제점을 데이터 관점에서 분석 후,"
                            : "Please Write"}
                        <Pcbr />
                        {language === "ko"
                            ? "해결위한 필요 액션 추천"
                            : "Please Write"}
                    </SpecialTitle>
                    <SpecialSub>
                        {language === "ko"
                            ? "대다수의 분석 업체들이 비즈니스 이해 없이 단순 통계 분석만 제공하고있으나, "
                            : "Please Write"}
                        <Pcbr />
                        {language === "ko"
                            ? "Datacore은 비즈니스 관점에서 유의미한 인사이트를 도출하여 성과개선을 위해 필요 활동을 제안합니다."
                            : "Please Write"}    
                    </SpecialSub>
                </SpecialFunctions>
            </SpecialContainer>
        </SpecialWrapper>
    );
};


const SpecialWrapper = styled.div`
    padding: 120px 32px;
    background-color: #010633;
    height: auto;
    width: 100%;
    box-sizing: border-box;

    /* @media all and (max-width: 768px) {
        padding: 120px 16px;
    } */
`;

const SpecialText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo} !important;
    font-family: ${({ theme }) => theme.fonts.bold} !important;
    line-height: 1.3;
    color: white;
    text-align: center;
    @media all and (max-width: 768px) {
        padding: 0 32px;
        font-size: ${({ theme }) => theme.fontSize.medium} !important;
    }
`;

const SpecialContainer = styled.div`
    background-color: #f0f0f0;
    width: 100%;
    margin: 0 auto;
    padding: 48px 16px;
    border-radius: 24px;
    margin-top: 64px;
    max-width: 1280px;
    box-sizing: border-box;
`;

const SliderWrapper = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
`;

const AnalysisWrapper = styled.div`
    width: 100% !important;
    height: auto;
    padding: 8px;
`;

const Analysis = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 64px;
    height: auto;
`;

const AnalysisTitle = styled.p`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold};
    color: white;
    margin: 16px 0;
`;

const AnalysisSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: white;
`;

const AnalysisImage = styled.img`
    width: 100%;
    height: auto;
    max-height: 360px;
    box-sizing: border-box;
    object-fit: unset;
    border-radius: 24px;

    @media all and (max-width: 375px) {
        max-height: 220px;
    }

`;
const SpecialTitle = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.medium};
    color: #000000;
    text-align: center;
    line-height: 1.2;

    @media all and (max-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.medium};
    }
`;

const SubText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: white;
    text-align: center;
    line-height: 1.5;
`;

const SpecialSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: #000000;
    text-align: center;
    line-height: 1.5;
`;

const SpecialFunctions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Pcbr = styled.br`
    @media all and (max-width: 1280px) {
        display: none;
    }
`;