import styled from "styled-components";
import {
    CaseBtn,
    GradientText,
    ListBanner,
    ListContainer,
    ListContents,
    ListSub,
} from "./PCIntro";
import useLanguageStore from "../store/useLanguageStore";

const MoIntro = () => {

    const language = useLanguageStore((state) => state.language);

    return (
        <>
            <ListContainer>
                <ListContents>
                    <ListBanner>
                        
                        {language === "ko" ? "Datacore의" : "Please Write"}
                        <br />
                        <GradientText>{language === "ko" ? "차별화된 서비스를" : "Please Write"}</GradientText>
                        <br />
                        {language === "ko" ? "만나보세요." : "Please Write"}
                    </ListBanner>
                    <ListSub>
                        {language === "ko" ? "실시간으로 변화하는 비즈니스 환경에" : "Please Write"}
                        <br />
                        {language === "ko" ? "발빠르게 대응할 수 있는 서비스를 제공합니다." : "Please Write"}
                    </ListSub>
                </ListContents>
                <MoCaseContaienr>
                    <MoCases>
                        <MocaseTitle>Data Transformation</MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_3_13.png" />
                        <MocaseText>
                            {language === "ko" ? "Data Transformation. 데이터가 가져올 혁신. 이를 위한 변화" : "Please Write"}
                        </MocaseText>
                        <CaseBtn to="/detail/1">{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </MoCases>
                    <MoCases>
                        <MocaseTitle>Data Aggregation + Analytics</MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_2.png" />
                        <MocaseText>
                            {language === "ko" ? "트랜드 변화를 파악을 남들보다 빠르게. Business question의 답은 데이터에 있습니다." : "Please Write"}
                        </MocaseText>
                        <CaseBtn to="/detail/2">{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </MoCases>
                    <MoCases>
                        <MocaseTitle>
                            Machine Learning model building
                        </MocaseTitle>
                        <MocaseImg src="https://heidimoon.cafe24.com/renwal/powerBI_1.png" />
                        <MocaseText>
                            {language === "ko" ? "어렵게만 느껴졌던 머신러닝. 내 비지니스를 위한 커스터마이즈 된 솔루션." : "Please Write"}
                        </MocaseText>
                        <CaseBtn to="/detail/3">{language === "ko" ? "" : "Please Write"}{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </MoCases>
                </MoCaseContaienr>
            </ListContainer>
        </>
    );
};

const MoCaseContaienr = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
    min-width: 320px;
    box-sizing: border-box;
    padding-bottom: 10rem;
`;

const MoCases = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f0f0f0;
    margin-top: 8rem;
    border-radius: 24px;
    padding: 16px;
`;

const MocaseTitle = styled.p`
    margin-top: 2rem;
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.bold} !important;

    color: #000000;
    margin-bottom: 1rem;
`;

const MocaseImg = styled.img`
    width: 100%;
    box-sizing: border-box;
    object-fit: contain;
`;

const MocaseText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular} !important;
    color: #000000;
    margin: 1rem 0;
`;

export default MoIntro;