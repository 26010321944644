import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useLanguageStore from "../store/useLanguageStore";

const PcIntro = () => {

    const language = useLanguageStore((state) => state.language);
    const [activeCaseIndex, setActiveCaseIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const caseContainers = document.querySelectorAll(".case-container");
            caseContainers.forEach((container, index) => {
                const rect = container.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // container가 화면 안에 있는 경우 active 상태로 설정
                if (index === 0 && rect.top >= 0) {
                    setActiveCaseIndex(0);
                } else if (rect.top >= 0 && rect.bottom <= windowHeight) {
                    setActiveCaseIndex(index);
                } else if (rect.top < 0 || rect.bottom > windowHeight) {
                    setActiveCaseIndex((prevIndex) =>
                        prevIndex === index ? null : prevIndex,
                    );
                }
            });
        };

        const optimizedScroll = () => {
            requestAnimationFrame(handleScroll);
        };

        window.addEventListener("scroll", optimizedScroll);
        return () => window.removeEventListener("scroll", optimizedScroll);
    }, []);
    return (
        <>
            <ListContainer>
                <ListContents>
                    <ListBanner>
                        {language ==="ko" ? "Datacore의" : "Please Write"}
                        <br />
                        <GradientText>{language ==="ko" ? "차별화된 서비스를" : "Please Write"}</GradientText>
                        <br />
                        {language ==="ko" ? "경험해 보세요." : "Please Write"}
                    </ListBanner>
                    <ListSub>
                        {language ==="ko" ? "빠르게 변화하는 비즈니스 환경에" : "Please Write"}
                        <br />
                        {language ==="ko" ? "신속하게 대응 가능한 맞춤형 서비스를 제공합니다." : "Please Write"}
                    </ListSub>
                </ListContents>
                <CaseContainer
                    className="case-container"
                    isActive={activeCaseIndex === 0}
                >
                    <CaseTitleWrapper>
                        <CaseTitle>Data Transformation</CaseTitle>
                        <CaseBtn to="/detail/1">{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </CaseTitleWrapper>
                    <CaseWraaper>
                        <Cases>
                            <Case>{language ==="ko" ? " Database 구축 " : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "데이터베이스를 통해 기업의 정보 자산 활용 최적화" : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "클라우드 migration" : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "현재 비지니스에 필요한 자동화 작업 등을 클라우드 서비스로 이관" : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>Live visualization</Case>
                            <CaseSub>
                                {language ==="ko" ? "비지니스가 가지고 있는 KPI, 시장상황 등을 라이브로 보여주는 BI solution 구축" : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>자동화</Case>
                            <CaseSub>
                                {language ==="ko" ? "현재 매뉴얼로 돌아가고 있는 업무, 프로세스등을 자동화 시켜 드립니다." : "Please Write"}
                            </CaseSub>
                        </Cases>
                    </CaseWraaper>
                </CaseContainer>
                <CaseContainer
                    className="case-container"
                    isActive={activeCaseIndex === 1}
                >
                    <CaseTitleWrapper>
                        <CaseTitle>Data Aggregation + Analytics</CaseTitle>
                        <CaseBtn to="/detail/2">{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </CaseTitleWrapper>
                    <CaseWraaper>
                        <Cases>
                            <Case>{language ==="ko" ? "시장 데이터" : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "이커머스 시장에서의 귀사와 경쟁사들 관련 데이터를 실시간으로 모으고, 분석해 드립니다" : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "광고 데이터" : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "귀사와 경쟁사의 광고 데이터를 모아, 트랜드, 퍼포먼스를 분석 해 드립니다" : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "리뷰 데이터" : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "귀사와 경쟁사의 리뷰 데이터를 모아 트랜드, 리뷰 키워드, 긍/부정 여부를 분석 해 드립니다." : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>여론 데이터</Case>
                            <CaseSub>
                                {language ==="ko" ? "메타 / 유튜브 / 네이버 카페 등 여론이 있는 소스의 데이터를 분석 해 드립니다." : "Please Write"}
                            </CaseSub>
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "연관성 분석" : "Please Write"}</Case>
                            <CaseSub>
                                {language ==="ko" ? "현재 귀사의 매출, 검색량 등 KPI와 다른 데이터의 연관도 분석을 해 드립니다." : "Please Write"}
                            </CaseSub>
                        </Cases>
                    </CaseWraaper>
                </CaseContainer>
                <CaseContainer
                    className="case-container"
                    isActive={activeCaseIndex === 2}
                >
                    <CaseTitleWrapper>
                        <CaseTitle>Machine Learning model building</CaseTitle>
                        <CaseBtn to="/detail/3">{language ==="ko" ? "자세히보기" : "Please Write"}</CaseBtn>
                    </CaseTitleWrapper>

                    <CaseWraaper>
                        <Cases>
                            <Case>{language ==="ko" ? "비용 최적화 계산 모델" : "Please Write"}</Case>
                            {/* <CaseSub>설명</CaseSub> */}
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "Supply chain을 위한 수요 예측 모델" : "Please Write"}</Case>
                            {/* <CaseSub>설명</CaseSub> */}
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "귀사 데이터 분석을 쉽게 돕기 위한 챗봇" : "Please Write"}</Case>
                            {/* <CaseSub>설명</CaseSub> */}
                        </Cases>
                        <Cases>
                            <Case>{language ==="ko" ? "매출 예측을 위한 강화 학습 모델" : "Please Write"}</Case>
                        </Cases>
                    </CaseWraaper>
                </CaseContainer>
                <ListBg
                    src="https://heidimoon.cafe24.com/renwal/3D%20Chart.png"
                    alt="3D Chart"
                />
            </ListContainer>
        </>
    );
};

const CaseContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    transition: all 0.3s ease;
    filter: ${({ isActive }) => (isActive ? "none" : "blur(5px)")};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

const ListContainer = styled.div`
    background-color: #010633;
    position: relative;
    height: auto;
    width: 100%;
    padding: 5% 32px 10% 32px;
`;

const ListContents = styled.div`
    width: 100%;
    margin: 0 auto 120px auto;

    @media (max-width: 1024px) {
        padding: 5% 16px;
    }
`;

const ListBanner = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo};
    font-family: ${({ theme }) => theme.fonts.bold} !important;
    color: white;

    @media all and (max-width: 576px) {
        font-size: ${({ theme }) => theme.fontSize.large};
    }
`;

const ListBg = styled.img`
    position: absolute;
    right: 0;
    width: 40%;
    height: auto;
    top: 0%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const ListSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    color: white;
    font-family: ${({ theme }) => theme.fonts.regular} !important;
    margin-top: 1rem;
`;

const GradientText = styled.span`
    font-size: ${({ theme }) => theme.fontSize.logo};
    font-family: ${({ theme }) => theme.fonts.bold} !important;
    background: ${({ theme }) =>
        theme.colors.sloganGradient}; /* 그라데이션 색상 */
    -webkit-background-clip: text; /* 텍스트에 배경 클립 적용 */
    -webkit-text-fill-color: transparent; /* 텍스트 색상을 투명하게 처리 */
    background-clip: text; /* 모던 브라우저용 */

    @media all and (max-width: 576px) {
        font-size: ${({ theme }) => theme.fontSize.large};
    }
`;

const CaseWraaper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 160px;
`;

const Cases = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    width: calc(33.33% - 16px);
`;

const CaseTitleWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const CaseBtn = styled(Link)`
    text-decoration: none;
    background-image: ${({ theme }) => theme.colors.mainGradient};
    border-radius: 5px;
    padding: 16px 16px;
    border: 0px solid #2f19bd;
    min-height: auto;
    max-width: none;
    line-height: inherit;
    color: white;
    cursor: pointer;
    flex-shrink: 0;
    text-align: center;

    @media all and (max-width: 765px) {
        width: 100px;
        padding: 8px;
    }
`;
const CaseTitle = styled.p`
    font-size: ${({ theme }) => theme.fontSize.logo};
    font-family: ${({ theme }) => theme.fonts.semibold};
    color: white;

    @media all and (max-width: 1024px) {
        font-size: 36px;
    }
`;

const Case = styled.p`
    font-size: ${({ theme }) => theme.fontSize.large};
    font-family: ${({ theme }) => theme.fonts.medium};
    color: white;
`;

const CaseSub = styled.p`
    font-size: ${({ theme }) => theme.fontSize.small};
    color: white;
    font-family: ${({ theme }) => theme.fonts.regular};
    margin-top: 1rem;
`;

export {
    PcIntro,
    ListContainer,
    ListContents,
    ListBanner,
    GradientText,
    ListSub,
    CaseBtn,
    CaseTitleWrapper,
};