import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useLanguageStore from "../store/useLanguageStore";



const PCHeader = forwardRef((props, ref) => {

    const { language, setLanguage } = useLanguageStore(); 

    const toggleLanguage = () => {
        setLanguage((prevLang) => (prevLang === "ko" ? "en" : "ko"));
    };

    return (
        <Wrapper>
            <HeaderContainer ref={ref}>
                <LogoArea>
                    <Logo to="/">Datacore</Logo>
                </LogoArea>
                <CategoryArea>
                    <CategoryLink to="/prdList">{language === "ko" ? "제품 소개" : "Product Overview"}</CategoryLink>
                    <DetailService>
                        <CategoryLink to="/detail/1">
                        
                        {language === "ko" ? "상세서비스" : "Service Details"}
                        </CategoryLink>
                        <Dropdown>
                            <DropdownItem to="/detail/1">
                                Data Transformation
                            </DropdownItem>
                            <DropdownItem to="/detail/2">
                                Data Aggregation & Analytics
                            </DropdownItem>
                            <DropdownItem to="/detail/3">
                                Machine Learning model building
                            </DropdownItem>
                        </Dropdown>
                    </DetailService>
                </CategoryArea>
                <InquiryBtn to="/ContactUs">
                {language === "ko" ? "문의하기" : "Contact"}
                </InquiryBtn>
                {/* <TranslationLabel onClick={toggleLanguage}>
                {language === "ko" ? "🇺🇸 ENGLISH" : "🇰🇷 한국어"}
                </TranslationLabel> */}
            </HeaderContainer>
        </Wrapper>
    );
});

const MOHeader = forwardRef(( props, ref) => {

    const { language, setLanguage } = useLanguageStore(); 

    const toggleLanguage = () => {
        setLanguage((prevLang) => (prevLang === "ko" ? "en" : "ko"));
    };


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
        setIsDropdownOpen(false);
    };

    return (
        <Wrapper>
            <HeaderContainer ref={ref}>
                <TilteArea>
                    <LogoArea>
                        <Logo to="/">Datacore</Logo>
                    </LogoArea>
                    <MenuBar
                        src={
                            isMenuOpen
                                ? "https://magicsecret.cafe24.com/renewal/draft/close.svg"
                                : "https://magicsecret.cafe24.com/renewal/sidebar_02.svg"
                        }
                        $isOpen={isMenuOpen}
                        onClick={handleMenuClick}
                    />
                </TilteArea>
            </HeaderContainer>
            <SlideMenu $isOpen={isMenuOpen}>
                <MenuItem to="/prdList" onClick={closeMenu}>
                {language === "ko" ? "Product Overview" : "Please Write"}
                </MenuItem>
                <MenuWrapper onClick={toggleDropdown}>
                    {language === "ko" ? "상세서비스" : "Service Details"}
                    <MenuDetailImg
                        src={
                            isDropdownOpen
                                ? "https://heidimoon.cafe24.com/renwal/minus.svg"
                                : "https://magicsecret.cafe24.com/renewal/sidebar_plus.svg"
                        }
                        $isDropdownOpen={isDropdownOpen}
                    />
                </MenuWrapper>
                <MenuDropdown $isOpen={isDropdownOpen}>
                    <MenuDropdownItem to="/detail/1" onClick={closeMenu}>
                    {language === "ko" ? "데이터 자동화" : "Data Transformation"}
                    </MenuDropdownItem>
                    <MenuDropdownItem to="/detail/2" onClick={closeMenu}>
                    {language === "ko" ? "데이터 수집 및 분석" : "Data Aggregation/Analytics"}
                        
                    </MenuDropdownItem>
                    <MenuDropdownItem to="/detail/3" onClick={closeMenu}>
                    {language === "ko" ? "머신러닝" : "Machine Learning model building"}
                        
                    </MenuDropdownItem>
                </MenuDropdown>
                <MenuItem to="/ContactUs" onClick={closeMenu}>
                {language === "ko" ? "문의하기" : "Contact"}
                </MenuItem>
                {/* <TranslationLabel onClick={toggleLanguage}>
                {language === "ko" ? "🇺🇸 ENGLISH" : "🇰🇷 한국어"}
                </TranslationLabel> */}
            </SlideMenu>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    background-color: ${({ isScrolled }) =>
        isScrolled ? "white" : "transparent"};
    transition: background-color 0.3s ease;

    @media all and (min-width: 1025px) {
        background-color: #1b1b1b;
    }

    @media all and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
        background-color: ${({ isScrolled }) =>
            isScrolled ? "white" : "transparent"};
        height: 0;
        width: 100%; /* 추가: 작은 화면에서 너비를 100%로 설정 */
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
    margin: 0 auto;
    max-width: 1280px;

    @media all and (min-width: 1025px) {
        justify-content: space-between;
        align-items: center;
        padding: 0 32px;
    }
    @media all and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const TilteArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media all and (max-width: 1024px) {
        padding: 8px 16px;
        background-color: white;
    }
`;

const LogoArea = styled.div`
    cursor: pointer;

    @media all and (min-width: 1025px) {
        flex-shrink: 1;
    }
    @media all and (max-width: 1024px) {
    }
`;

const Logo = styled(Link)`
    letter-spacing: 0px;
    font-weight: bold;
    font-style: normal;
    line-height: inherit;
    font-size: ${({ theme }) => theme.fontSize.medium};
    text-decoration: none;

    @media all and (min-width: 1025px) {
        color: #ffffff;
    }
    @media all and (max-width: 1024px) {
        color: black;
    }

    @media all and (max-width: 576px) {
        color: black;
    }
`;

const InquiryBtn = styled(Link)`
    text-decoration: none;
    background-image: ${({ theme }) => theme.colors.mainGradient};
    border-radius: 5px;
    padding: 12px 30px;
    border: 0px solid #2f19bd;
    min-height: auto;
    max-width: none;
    line-height: inherit;
    color: white;
    cursor: pointer;
    flex-shrink: 0;

    @media all and (max-width: 1024px) {
        padding: 8px 24px;
    }
`;

const CategoryArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media all and (min-width: 1025px) {
        flex-shrink: 1;
        flex-grow: 0.5;
    }
    @media all and (max-width: 1024px) {
        width: 100%;
        height: auto;
        padding: 16px 0;
        box-sizing: border-box;
    }
`;

const CategoryLink = styled(Link)`
    font-size: ${({ theme }) => theme.fontSize.small};
    cursor: pointer;
    white-space: nowrap;
    padding: 20px 0;
    text-decoration: none;
    @media all and (min-width: 1025px) {
        color: #ffffff;
    }
    @media all and (max-width: 1024px) {
        color: rgba(0, 0, 0, 0.5);
    }

    @media all and (max-width: 576px) {
        color: rgba(0, 0, 0, 0.5);
    }
`;

const DetailService = styled.div`
    position: relative;
    &:hover div {
        opacity: 1;
        transform: translateY(0);
        display: block;
    }
`;

const Dropdown = styled.div`
    opacity: 0;
    transform: translateY(10px); /* 아래로 내려오는 애니메이션 */
    position: absolute;
    top: 150%; /* CategoryLink 바로 아래에 드롭다운 위치 */
    left: -70px;
    background-color: #1b1b1b;
    border: 1px solid #ccc;
    border-radius: 24px;
    padding: 20px;
    text-align: center;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    transition: opacity 0.3s ease, transform 0.3s ease;

    /* DetailService 전체가 hover될 때 드롭다운 유지 */
    ${DetailService}:hover & {
        opacity: 1;
        transform: translateY(10px);
        display: block;
    }
`;

const DropdownItem = styled(Link)`
    margin-bottom: 24px;
    &:last-child {
        margin-bottom: 0;
    }

    color: white;
    text-decoration: none;
    display: block;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
`;

const MenuBar = styled.img`
    width: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(90deg)" : "rotate(0deg)")};
`;

const SlideMenu = styled.div`
    position: fixed;
    top: 35px;
    left: ${({ $isOpen }) => ($isOpen ? "0" : "-100%")};
    width: 100%;
    height: 100%;
    background-color: white;
    transition: left 0.3s ease;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
`;

const MenuItem = styled(Link)`
    padding: 16px;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
    &:last-child {
        border-bottom: none;
    }
`;

const MenuWrapper = styled.div`
    width: 92%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
`;

const MenuDetailImg = styled.img`
    width: 20px;
    transition: transform 0.3s ease; /* 애니메이션 추가 */

    transform: ${({ $isDropdownOpen }) =>
        $isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)"};
`;

const MenuDropdown = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #010633;
    margin: 0 16px;
    border-radius: 24px;
    overflow: hidden;
    max-height: ${({ $isOpen }) =>
        $isOpen ? "500px" : "0"}; /* 높이를 0으로 설정해 공백 제거 */
    transform: ${({ $isOpen }) =>
        $isOpen
            ? "translateY(0)"
            : "translateY(-200%)"}; /* Y축으로 내려오는 애니메이션 */
    opacity: ${({ $isOpen }) =>
        $isOpen ? "1" : "0"}; /* 열릴 때 투명도 조정 */
    transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease; /* 애니메이션 트랜지션 설정 */
`;

const MenuDropdownItem = styled(Link)`
    padding: 16px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    &:last-child {
        border-bottom: none;
    }
`;


const TranslationLabel = styled.p`

    padding: 16px;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    cursor:pointer;

    @media all and (max-width:1024px) {

        color:black;
        
    }
`

export { MOHeader, PCHeader, InquiryBtn };