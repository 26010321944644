import { create } from "zustand";

const useLanguageStore = create((set) => ({
    language: "ko",
    setLanguage: (lang) => set((state) => {
        // lang이 함수인 경우 처리
        const newLang = typeof lang === "function" ? lang(state.language) : lang;
        return { language: newLang };
    }),
}));


export default useLanguageStore;