import styled from "styled-components";
import useLanguageStore from "../store/useLanguageStore";

const Footer = () => {

    const language = useLanguageStore((state) => state.language); 

    return (
        <>
            <FooterWrapper>
                <SmallLogo>Datacore</SmallLogo>
                <FooterContainer>
                    <FooterPhone>{language ==="ko" ? "대표: 정재혁" : "CEO: 정재혁"}</FooterPhone>
                    <FooterPhone>{language ==="ko" ? "대표번호 : 010-8549-2249" : "Phone : 010-8549-2249"}</FooterPhone>
                    <FooterPhone>{language ==="ko" ? "사업자등록번호 :107-88-44286" : "Business Registration Number : 07-88-44286"}</FooterPhone>
                </FooterContainer>

                {/* <FooterContainer>
                    <FooterRule>이용약관</FooterRule>
                    <FooterRule>개인정보취급방침</FooterRule>
                </FooterContainer> */}
            </FooterWrapper>
        </>
    );
};

export default Footer;

const FooterWrapper = styled.div`
    background-color: #1b1b1b;
    padding: 120px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    width: 100%;
    gap: 16px;

    @media all and (max-width: 768px) {
        padding: 80px 0;
    }
`;

const SmallLogo = styled.p`
    color: white;

    font-size: ${({ theme }) => theme.fontSize.medium};
    font-family: ${({ theme }) => theme.fonts.bold};
`;

const FooterContainer = styled.div`
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

const FooterPhone = styled.p`
    color: white;

    font-size: ${({ theme }) => theme.fontSize.small};
    font-family: ${({ theme }) => theme.fonts.regular};
`;

// const FooterRule = styled.p`
//     cursor: pointer;
//     color: white;
//     text-align: center;
//     font-size: ${({ theme }) => theme.fontSize.small};
//     font-family: ${({ theme }) => theme.fonts.regular};
// `;